<template>
  <div>
    <el-dialog v-if="currentSurvey"
               width="75%"
               v-model="showForm"
               :before-close="closeDialog">
      <survey-form :survey="currentSurvey" :editable="isEditable" @after-save="closeDialog"></survey-form>
    </el-dialog>

  </div>
</template>

<script>
import SurveyForm from "./SurveyForm";

export default {
  name: "SurveyFormModal",
  props: ['modelValue', 'survey', 'editable'],
  components: {SurveyForm},
  data() {
    return {
      currentSurvey: null,
      showForm: false,
      isEditable: this.editable || false
    }
  },
  mounted: async function () {
    this.init()
  },
  watch: {
    'survey': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    init() {
      this.showForm = this.modelValue
      this.currentSurvey = this.survey
    },
    closeDialog() {
      this.showForm = false
      this.currentSurvey = null
      this.$emit('update:modelValue', false)
    },
  }
}
</script>
<style scoped>
</style>
