<template>
  <div>
    <survey-patient-form-modal :survey-patient="currentSurveyPatient" v-model="showSurveyPatientForm"></survey-patient-form-modal>

    <div v-if="currentSurvey">

      <h4>{{currentSurvey.title_short}}</h4>
      <h6>{{currentSurvey.title}}</h6>

          <el-row v-if="isEditable" class="mt-4">
            <el-col :span="7">
              <h6>Kurztitel</h6>
              <el-input v-model="currentSurvey.title_short"></el-input>
            </el-col>
            <el-col :span="15" :offset="1">
              <h6>Titel</h6>
              <el-input v-model="currentSurvey.title"></el-input>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col :span="7">
              <h6>Studie-Version</h6>
              <el-input v-if="isEditable" v-model="currentSurvey.version"></el-input>
              <span v-if="!isEditable">{{currentSurvey.version}}</span>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>Änderung der Studien-Version</h6>
              <div v-if="isEditable">
                <el-date-picker
                  class="w-100"
                  v-model="currentSurvey.version_date"
                  type="date"
                  format="DD.MM.YYYY"
                  value-format="YYYY-MM-DD"
                  placeholder="Datum auswählen">
                </el-date-picker>
              </div>
              <div v-else>
                {{$filters.germanDate(currentSurvey.version_date)}}
              </div>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col :span="7">
              <h6>Datum Beginn</h6>
              <div v-if="isEditable">
                <el-date-picker
                  class="w-100"
                  v-model="currentSurvey.date_start"
                  type="date"
                  format="DD.MM.YYYY"
                  value-format="YYYY-MM-DD"
                  placeholder="Datum auswählen">
                </el-date-picker>
              </div>
              <div v-else>
                {{$filters.germanDate(currentSurvey.date_start)}}
              </div>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>Datum Ende</h6>
              <div v-if="isEditable">
                <el-date-picker
                  class="w-100"
                  v-model="currentSurvey.date_end"
                  type="date"
                  format="DD.MM.YYYY"
                  value-format="YYYY-MM-DD"
                  placeholder="Datum auswählen">
                </el-date-picker>
              </div>
              <div v-else>
                {{$filters.germanDate(currentSurvey.date_end)}}
              </div>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>Status</h6>
              <div v-if="isEditable">
                <el-select v-model="currentSurvey.status_id">
                  <el-option
                    v-for="item in surveyStatus"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div v-else>
                {{currentSurvey.status.title}}
              </div>
            </el-col>

          </el-row>
          <el-row class="mt-4">
            <el-col :span="7">
              <h6>Diagnose</h6>
              <div v-if="isEditable">
                <el-select
                  v-model="currentSurvey.diagnosis"
                  filterable
                  multiple
                  class="w-100">
                  <el-option
                    v-for="item in diagnosis"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div v-else>
                <div v-if="currentSurvey.diagnosis">
                  <div v-for="diagnosis in currentSurvey.diagnosis" :key="diagnosis.id">
                    {{diagnosis.meta.diagnosis.title}}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>Behandlungssituationen</h6>
              <div v-if="isEditable">
                <el-select
                  v-model="currentSurvey.treatmentsituations"
                  filterable
                  multiple
                  class="w-100">
                  <el-option
                    v-for="item in treatmentsituations"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div v-else>
                <div v-if="currentSurvey.treatmentsituations">
                  <div v-for="treatmentsituation in currentSurvey.treatmentsituations" :key="treatmentsituation.id">
                    {{treatmentsituation.meta.treatmentsituation.title}}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>Biomarker</h6>
              <div v-if="isEditable">
                <el-select
                  v-model="currentSurvey.biomarker"
                  filterable
                  multiple
                  class="w-100">
                  <el-option
                    v-for="item in biomarker"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div v-else>
                <div v-if="currentSurvey.biomarker">
                  <div v-for="biomarker in currentSurvey.biomarker" :key="biomarker.id">
                    {{biomarker.meta.biomarker.title}}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col :span="24">
              <h6>Einschlusskriterien</h6>
              <div v-if="isEditable">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="Einschlusskriterien"
                  v-model="currentSurvey.inclusion_criteria"></el-input>
              </div>
              <div v-else>
                {{currentSurvey.inclusion_criteria}}
              </div>
              <h6 class="mt-3">Ausschlusskriterien</h6>
              <div v-if="isEditable">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="Ausschlusskriterien"
                  v-model="currentSurvey.exclusion_criteria"></el-input>
              </div>
              <div v-else>
                {{currentSurvey.exclusion_criteria}}
              </div>
              <div class="mt-3" v-if="!isEditable && currentSurvey.notice">
                <h6>Memo</h6>
                {{currentSurvey.notice}}
              </div>
              <div v-if="isEditable" class="mt-3">
                <h6>Memo</h6>
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="Memo"
                  v-model="currentSurvey.notice"></el-input>
              </div>
            </el-col>

          </el-row>

          <div v-if="isEditable" class="mt-3">
            <h6>Aufgaben</h6>
            <el-select
              v-model="currentSurvey.tasks"
              filterable
              multiple
              class="w-100">
              <el-option
                v-for="item in surveyTasks"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </div>

          <div v-if="isEditable" class="mt-3">
            <h6>CRO</h6>
            <el-select
              v-model="currentSurvey.cro_id"
              filterable
              class="w-100">
              <el-option label="Keine Auswahl" :value="null"></el-option>
              <el-option
                v-for="item in cro"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </div>


          <div class="mt-4 text-right">
            <el-button v-if="isEditable" type="warning" @click="onSaveSurvey">Studie speichern</el-button>
            <el-button v-if="!isEditable" type="warning" @click="turnEditableOn">Studie in Bearbeitungsmodus öffnen</el-button>
          </div>

    </div>

  </div>
</template>

<script>
import SurveyPatientFormModal from "./SurveyPatientFormModal";
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "SurveyForm",
  props: ['survey', 'editable'],
  components: {
    SurveyPatientFormModal
  },
  data() {
    return {
      currentSurvey: null,
      currentSurveyPatient: null,
      showSurveyPatientForm: false,
      isEditable: this.editable || false
    }
  },
  mounted: async function () {
    this.getDiagnosis()
    this.getTreatmentsituations()
    this.getBiomarker()
    this.getSurveyTasks()
    this.getSurveyStatus()
    this.getCRO()
    await this.loadSurvey()
  },
  computed: {
    ...mapGetters('diagnosis', ['diagnosis']),
    ...mapGetters('treatmentsituations', ['treatmentsituations']),
    ...mapGetters('biomarker', ['biomarker']),
    ...mapGetters('surveys', ['surveyTasks', 'surveyStatus']),
    ...mapGetters('cro', ['cro']),

  },
  watch: {
    'survey': function() {
      this.loadSurvey()
    },
  },
  methods: {
    ...mapActions('diagnosis', ['getDiagnosis']),
    ...mapActions('treatmentsituations', ['getTreatmentsituations']),
    ...mapActions('biomarker', ['getBiomarker']),
    ...mapActions('surveys', ['getSurveyTasks', 'saveSurvey', 'getSurveyStatus']),
    ...mapActions('cro', ['getCRO']),

    async turnEditableOn() {
      this.loadFormMeta()
      this.isEditable = true
    },
    async loadFormMeta() {
      this.currentSurvey.diagnosis = this.currentSurvey.diagnosis.map((d) => d.diagnosis_id)
      this.currentSurvey.treatmentsituations = this.currentSurvey.treatmentsituations.map((d) => d.treatmentsituation_id)
      this.currentSurvey.biomarker = this.currentSurvey.biomarker.map((d) => d.biomarker_id)
      this.currentSurvey.tasks = this.currentSurvey.tasks.map((d) => d.id)
    },
    async loadSurvey(survey) {

      if(this.survey === null) return

      this.currentSurvey = survey ? survey : _.cloneDeep(this.survey)
      this.isEditable = this.editable || false

      if(this.isEditable) this.loadFormMeta()

    },
    async onSaveSurvey() {

      if(this.currentSurvey.title_short === '' || !this.currentSurvey.title_short) {
        this.$notify.error({
          message: "Bitte geben Sie einen Kurztitel ein",
          position: 'bottom-left'
        });
        return
      }
      if(!this.currentSurvey.date_start) {
        this.$notify.error({
          message: "Bitte geben Sie \"Datum Beginn\" an",
          position: 'bottom-left'
        });
        return
      }
      if(!this.currentSurvey.status_id) {
        this.$notify.error({
          message: "Bitte geben Sie einen Status an",
          position: 'bottom-left'
        });
        return
      }

      const result = await this.saveSurvey(this.currentSurvey)
      if(!result) {
        this.$notify.error({
          message: "Fehler beim Speichervorgang",
          position: 'bottom-left'
        });
      }
      else {
        this.$notify({
          message: 'Studie wurde gespeichert',
          type: 'success',
          position: 'bottom-left'
        });
        this.$emit("after-save")
        // this.loadSurvey(_.cloneDeep(result))
      }

    },
    openSurveyPatientForm(surveyPatient) {
      this.currentSurveyPatient = surveyPatient
      this.showSurveyPatientForm = true
    }
  }
}
</script>
<style scoped>
</style>
